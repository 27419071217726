import  React, {useEffect, useState} from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import logo from '../assets/css/images/PECLogo-clr.png'
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import hdLogo from '../assets/css/hd-logo-clr.png'
// import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Image } from 'react';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {Link, useNavigate} from 'react-router-dom'
// import logo from '../assets/css/logo/logo.png'
import axios from 'axios'
import PropTypes from 'prop-types';
import { API } from "../Controllers/API";
import Cookies from 'js-cookie';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { ContactSupportOutlined } from '@mui/icons-material';
import { Spinner } from 'react-bootstrap';
// import { Dots } from "react-activity";
// import "react-activity/dist/library.css";


 
async function loginUser(credentials) {


  console.log(credentials)
   return fetch(`${API}login-1?${credentials}`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
   
  })
    .then(data => data.json())
 }


function Copyright(props) {
  return (
    <div style={{textAlign:'center'}}>
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
     
        Andere HR Inc 
    
      {new Date().getFullYear()}
      {'.'}
    </Typography>
    <img src={hdLogo} height='auto' width='100px'/>
    </div>
  );
}

const theme = createTheme({
    palette: {
        primary: {
          main: '#199A71',
        
        },
      }, 
    }
);



export default function Login({setToken}) {
  const[isLoggingIn,setIsLoggingIn] = useState(false)
  const[username,setUser] = useState('')
  const[password,setPass] = useState('')
  const[activity,setActivity] = useState('none')
  const [open, setOpen] = useState(false);
  useEffect(()=>{
    let cookie = Cookies.get()
    console.log(cookie);
    if(!cookie.user || cookie.user === 'null'){
      console.log('no cookie')
    } else{
      setOpen(true)
      console.log('start login')
      loginUsingCookie(cookie.user)
     
    }
  },[])
  
const loginUsingCookie = async(a)=>{
  let email = a.toLowerCase();
    const token = await axios.get(API+`login-with-cookie?email=${email}&subaccount=false`)
        console.log(token.data.token)
        if(token.data.token == 'test123'){
          setToken(token.data)
          setActivity('none')
          setIsLoggingIn(false)
        } 
        if(token.token == 'inactive'){
          alert('There is no active memebership with this account.')
          setActivity('none')
           setIsLoggingIn(false)
        }
        if(token.token == false){
          alert('There is no active memebership with this account.')
          setActivity('none')
          setIsLoggingIn(false)
        }
      
}



const loginAgain = async (a,b)=>{
  const token = await loginUser(`user=${a}&pass=${b}`);
  console.log(token)
  if(token.token == 'test123'){
    setToken(token)
    setActivity('none')
    setIsLoggingIn(false)
  } 
  if(token.token == 'inactive'){
    alert('There is no active memebership with this account.')
    setActivity('none')
     setIsLoggingIn(false)
  }
  if(token.token == false){
    alert('There is no active memebership with this account.')
    setActivity('none')
    setIsLoggingIn(false)
  }
}
    
  const handleSubmit = async e => {
    setActivity('flex')
    setIsLoggingIn(true)
    e.preventDefault();
    const token = await loginUser(`user=${username}&pass=${password}`
     );
     console.log(token)
     
     if(token.action === 'registered'){
      loginAgain(username, password)
      
     } else{
      
  
      if(token.token == 'test123'){
        setToken(token)
        setActivity('none')
        setIsLoggingIn(false)
      } 
      if(token.token == 'inactive'){
        alert('There is no active memebership with this account.')
        setActivity('none')
        setIsLoggingIn(false)
      }
      if(token.token == false){
        alert('There is no active memebership with this account.')
        setActivity('none')
        setIsLoggingIn(false)
      }

     }
   
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          
          <img style={{width:'400px'}} src={logo}/>
          {/* <Typography component="h1" variant="h5">
            Sign in
          </Typography> */}
          <form onSubmit={handleSubmit}>
          <Box  sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              type="email"
              autoComplete="email"
              autoFocus
              onChange={(e)=>{setUser(e.target.value);console.log(username);}}
            />
            <Typography variant='subtitle2'>Use the email address associated with your account.</Typography>
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              onChange={(e)=>{setPass(e.target.value);console.log(password);}}
              autoComplete="current-password"
            />
            {/* <FormControlLabel
              control={<Checkbox value={isRemembered} color="primary" />}
              label="Remember Username"
            /> */}
            {/* <Link style={{color:'white',textDecoration:'none'}} to="/dashboard"> */}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            //   onClick={()=>{
            //       handleSubmit();
            //   }
            // 
            >
              Log In
             {/* <spn style={{marginLeft:10, paddingBottom:2, display:activity}}> <Dots/></span> */}
             <span>&nbsp;</span><Spinner style={{display: isLoggingIn ? 'block': 'none'}} size="sm"  animation="border" variant="light" /></Button>

            <Grid container>
              <Grid item xs>
                <a target='_blank' href="/#/password-reset" >
                  Forgot password?
                </a>
              </Grid>
              <Grid item>
                {/* <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link> */}
              </Grid>
            </Grid>
          </Box>
          </form>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
       <Dialog open={open}>
        <Box sx={{padding:'50px', justifyContent:'center'}}>
         
          <Typography sx={{color:'#199A71', fontSize:'20px'}} variant='button'><b>Loading User Data</b></Typography>
         
          <br></br>
          <Box style={{display:'block', textAlign:'center', marginTop:'20px'}}>
            <p>
          <Spinner variant='success' sx={{color:'#199A71'}} size='lg'/>
          </p>
          </Box>
        </Box>
       </Dialog>
      </Container>
    </ThemeProvider>
  );
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired
}