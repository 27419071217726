
import React, { useEffect, useState } from 'react';
import { Button, Container, CssBaseline, TextField, Typography } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import logo from '../assets/css/images/PECLogo-clr.png'
import { styled } from '@mui/system';
import axios from 'axios';
import { API } from '../Controllers/API';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';

const CenteredContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop:'10%', // Add this to center horizontally as well
    height: '100vh', // Use the full viewport height
  });
  
  const Avatar = styled(LockOutlinedIcon)(({ theme }) => ({
    margin: theme.spacing(1),
    backgroundColor: 'green',
  }));
  
//   const Form = styled('form')(({ theme }) => ({
//     width: '100%', // Fix IE 11 issue.
//     marginTop: theme.spacing(1),
//   }));
  
  const SubmitButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(3, 0, 2),
    backgroundColor:'#1A9A71'
  }));
  

  export const PasswordReset = ()=>{
    const [showSuccess, setShowSuccess] = useState(false);
    const [email, setEmail] = useState('');
    const [role, setRole] = useState('---');

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
      };

      const submit = async (e)=>{
         e.preventDefault();
        console.log(email, role)
         if(role === '---'){
            window.alert('Please select your account role.')
         } else {
            if(role=== 'admin'){
              let resp = await axios.post(`https://helpdeskforhr.com/?rest_route=/simple-jwt-login/v1/user/reset_password&email=${email}`);
              if(resp){
                setShowSuccess(true)
              }
            } else {
                let resp2 = await axios.post(API+ 'send-reset-email', {email:email});
                  console.log(resp2)
                if(resp2.data === 'error'){
                    window.alert('The email could not be found in our system. Please check the spelling and try again.')
                } else {
                    setShowSuccess(true)
                }

            }
         }
      }
    
    if(showSuccess === false){
    
        return (
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <CenteredContainer>
              <img src={logo} width="400px" style={{marginBottom:10}} height="auto"/>
            
     
           
              <Typography sx={{fontSize:14}} variant="button">
              <b>What is your account role?</b>
              </Typography>
              <Form onSubmit={submit}>
                <Form.Select onChange={(e)=>{setRole(e.target.value)}}>
                    <option value='---'>---</option>
                    <option value="admin">Account Holder</option>
                    <option value="sub">Secondary Admin</option>
                </Form.Select>

                <Typography sx={{fontSize:14}} variant="button">
                    <br></br>
              <b>Enter the email address attached to the account.</b>
              </Typography>
                <TextField
             
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="password"
                  label="Email Address"
                  name="password"
                  value={email}
                  onChange={handleEmailChange}
                />
               
                <SubmitButton
                type='submit'
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                 <b>Reset Password</b>
                </SubmitButton>
                </Form>
            </CenteredContainer>
          </Container>
        );
      } else {
          return(
          <Container component="main" maxWidth="xs">
          <CssBaseline />
          <CenteredContainer>
            <img src={logo}  width="400px" height="auto"/>
            {/* <Avatar>
              <LockOutlinedIcon />
            </Avatar> */}
              <Typography sx={{textAlign:'center',marginBottom:5, marginTop:5, fontSize:20}}  variant="button">
           <b> Successfully requested password reset.  </b>
            </Typography>
            <Typography sx={{textAlign:'center', fontSize:16}}>If the email is in our system, an email will be sent to {email}. Please click the link in that email to reset your password.</Typography>
          
             <Link to='/'><Button sx={{backgroundColor:'#1A9A71', marginTop:2}} variant='contained'><b>Go to Login</b></Button></Link>
          </CenteredContainer>
        </Container>
        )
      }
  }