import React, { useEffect, useRef, useState } from "react";
import { getDocuments } from "../config/api";
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {TextField, Typography} from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Paper from '@mui/material/Paper';
import axios from "axios";
import { Button } from "@mui/material";
import { Modal } from "react-bootstrap";
import data from '../json/employees.json'
import SelectUploadButtonGroup from "../components/SelectUploadButtonGroup";
import '../assets/css/selectionCard.css'
import AddEmployeeForm from "../components/AddEmployeeForm";
import uuid from "react-uuid";
import {API, usercreds} from '../Controllers/API'
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import { AddMultipleEmployees } from "../components/AddMultipleEmployees";
import Papa from 'papaparse'
import { Upload } from "@mui/icons-material";
import { upload } from "@testing-library/user-event/dist/upload";
import { Form } from "react-bootstrap";
import { EditEmployeeForm } from "../components/EditEmployeeForm";
import ToolTipButton from "../components/TooltipButton";

function createData(name, lastReview, status, id) {
  return { name, lastReview, status, id };
}
const rows = []


function Row(props) {
  const data = JSON.parse(sessionStorage.getItem('profile-data'))


 

  const [open, setOpen] = React.useState(false);

  
  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {props.FirstName}
        </TableCell>
        <TableCell component="th" scope="row">
          {props.LastName}
        </TableCell>
          
           <TableCell align="left">{props.DateOfLastReview}</TableCell>
           <TableCell align="left">{props.Role}</TableCell>
           <TableCell align="left">{props.Email}</TableCell>
           <TableCell align="center">{props.EditButton} {props.DeleteButton}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
            {/* <Typography variant="button" gutterBottom component="div">
              <b>Review Type:</b> <span>{row.type}</span> 
              </Typography> 
              <Typography variant="button" gutterBottom component="div">
               Reviewer Details
              </Typography> */}
              
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Date Created</TableCell>
                    <TableCell>ReviewType</TableCell>
                    <TableCell align="left">Status</TableCell>
                    
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.Reviews.map((i) => {
                    let statusArr = [];
                    i.reviewers.forEach(i=>{
                      statusArr.push(i.status)
                    })


                   return (
                    <TableRow key={i.reviewId}>
                      <TableCell align="left" component="th" scope="row">
                        {i.dateCreated}
                      </TableCell>
                      <TableCell align="left">{i.reviewType}</TableCell>
                      <TableCell align="left" style={{color: statusArr.includes('pending')? 'orange':'#199A71', fontWeight:'bold'}}>{statusArr.includes('pending')? 'Pending': 'Completed √' }</TableCell>
                  
                    </TableRow>
)})}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
   
    </React.Fragment>
  );
}


const Employees = ()=>{
  const data = JSON.parse(sessionStorage.getItem('profile-data'))

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [displayBack, setDisplayBack] = React.useState('none');
  const [update, setUpdate] = React.useState(false);
  const[ result, setResult] = useState([])
  const[ displayAddBtn, setDisplayAddBtn] = useState(false)
  const[ uploadSelectDisplay, setUploadSelectDisplay] = useState(true)
  const[ addSingleEmployeeDisplay, setAddSingleEmployeeDisplay] = useState(false)
  const[ addMultipleEmployeeDisplay, setAddMultipleEmployeeDisplay] = useState(false)
  const [filter, setFilter] = useState('')
  const [roleFilter, setRoleFilter] = useState('')

  

 //Edit State Variables
 const [editFirstName, setEditFirstName] = useState('');
 const [editLastName, setEditLastName] = useState('');
 const [editEmail, setEditEmail] = useState('');
 const [editCategory, setEditCategory] = useState('');
 const [editId, setEditId] = useState('')
 const [showEdit, setShowEdit] = useState(false);
 const handleCloseEdit = () => setShowEdit(false);
 const handleShowEdit = () => setShowEdit(true);
 
const inputFile = useRef(null)
const[mainData, setMainData] = useState()
let firstNameInput = '';
let lastNameInput = '';
let nameInput = '';
let emailInput = '';
let employeeType = '';

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const addEmployees = async ()=>{
  console.log(data.id)
let resp = await  axios.post(API+'add-multiple-employees',{id:data.id, array:result})
console.log(resp.data)
handleClose()
setDisplayBack('none')
setAddMultipleEmployeeDisplay(false)
setUploadSelectDisplay(true)
setUpdate(!update)
setResult([])
}
const changeHandler = (event) => {
  console.log(event.target.files[0])
  Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        console.log(results.data)
         let arr = []

         results.data.forEach(i=>{
          let role = i.Role.toLowerCase();
           console.log(role)
          let roleFinal = capitalizeFirstLetter(role)
          if(roleFinal === "Manager" || roleFinal === 'Employee')
              arr.push({
                  id: uuid(),
                  firstName:i.FirstName,
                  lastName: i.LastName,
                  name: `${i.FirstName} ${i.LastName}`,
                  email: i.Email,
                  role: roleFinal,
                  dateOfLastReview:"NA",
                  reviews:[]

              })
              else{
                  window.alert("The document is not formatted correctly. Please make sure the roles are either set as 'Employee' or 'Manager' and then reupload the document.")
              }
         })
        if(arr.length === results.data.length){
          console.log(arr.length)
          setResult(arr)
          setDisplayAddBtn(true)
        } else{

        }
   

        console.log(arr.length)
      },
    });
};

const EditEmployee = async ()=>{

  let resp = await axios.post(API+'edit-employee',{
    userId: data.id,
    id: editId,
    firstName: editFirstName,
    lastName: editLastName,
    name: editFirstName + ' ' + editLastName,
    email: editEmail,
    role: editCategory
  })

  console.log(resp.data)
if(resp.data === 'error'){
   window.alert('The employee was not updated. Please try again.')
} else{
  window.alert(`The update for ${editFirstName + ' ' + editLastName} was successful!`)
  setUpdate(!update)
  handleCloseEdit()
}
 
  // let index = users[0].learners.findIndex(i=> {return editId === i.id})

  // users[0].learners[index].firstName = editFirstName;
  // users[0].learners[index].lastName = editLastName;
  // users[0].learners[index].email = editEmail;
  // users[0].learners[index].category = editCategory;

  // handleCloseEdit()
 
  //Tie to API
}

const LoadEditLearner = (a)=>{
  let filter = mainData.employees.filter(i =>{return (i.id === a)})
   setEditFirstName(filter[0].firstName)
   setEditLastName(filter[0].lastName)
   setEditEmail(filter[0].email)
   setEditCategory(filter[0].role)
   setEditId(a)
   handleShowEdit()
   console.log(filter)
  //Tie to API
}

useEffect(()=>{
  axios.post(API,{email:data.email}).then((response)=>{
   return response
   

      }).then((response)=>{
        setMainData(response.data[0])
        setLoading(false)
      })
},[])

useEffect(()=>{
  
  axios.post(API,{email:data.email}).then((response)=>{
   return response
   

      }).then((response)=>{
        setMainData(response.data[0])
        setLoading(false)
      })
},[update])
  const handleLastNameChange = (event) => {
    const value = event.target.value;
   lastNameInput = value
    console.log(nameInput)
  };

  const handleFirstNameChange = (event) => {
    const value = event.target.value;
   firstNameInput = value
    console.log(nameInput)
  };


const handleEmailInput = (event)=>{
  let val = event.target.value;

  emailInput = val
  console.log(emailInput)
}

const addPersonToData = (a,b,c,d)=>{
let obj = {
  id:uuid(),
  dateOfLastReview:"NA",
  firstName:a,
  lastName:b,
  name:`${a} ${b}`,
  email:c,
  role:d,
  reviews:[]
}


axios.post(API+'add-employee',{email:data.email, obj: obj}).then((response)=>{
    return response
   


      }).then((response)=>{
       let resp = response.data

       return resp
      }).then((resp)=>{
        console.log(resp)
        setMainData(resp[0])
      })


}

//NEW TABLE



  
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const removeEmployee = (a)=>{
   const conf = window.confirm('This action will delete this person and all of their review data. Are you sure you would like to do this?')
   if(conf){
    
    axios.post(API+'delete-employee',{email:data.email, id: a}).then((response)=>{
    return response
   


      }).then((response)=>{
        console.log(response.data)
        setMainData(response.data[0])
        setUpdate(!update)
      })

   }
  }
if(loading){
return(<div>Loading</div>)
} else{
  
    return (
        <div style={{textAlign:'left'}}>
          
<Typography><b>EMPLOYEES</b></Typography>
<div style={{textAlign:'end', paddingBottom:'20px'}}>
<Button style={{backgroundColor:'#52C47B', fontWeight:'bold'}} onClick={()=>{handleOpen()}} variant="contained">Add New Employee +</Button>
</div>
<Box sx={{marginBottom:10, display:'inline'}}>
  <Form.Control onChange={(e)=>{setFilter(e.target.value)}} placeholder="&#128269; Search by First or Last Name..." style={{width:'33%', display:'inline'}}/>
  <Form.Select onChange={(e)=>setRoleFilter(e.target.value)} style={{width:'25%', float:'right', display:'inline'}}>
    <option value="">Sort By</option>
    <option value="">All</option>
    <option value="Manager">Managers</option>
    <option value="Employee">Employees</option>
  </Form.Select>
</Box>
             <TableContainer style={{marginTop:'20px'}} component={Paper}>
             <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
          <TableCell />
            <TableCell align="left"><b>First  Name</b></TableCell>
            <TableCell align="left"><b>Last  Name</b></TableCell>
            <TableCell align="left"><b>Date of Last Review</b></TableCell>
            <TableCell align="left"><b>Role</b></TableCell>
            <TableCell align="left"><b>Email</b></TableCell>
            <TableCell align="center"><b>Actions</b></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
         {mainData.employees.filter(f=>f.role.toLowerCase().includes(roleFilter.toLowerCase())).filter(o=> o.name.toLowerCase().includes(filter.toLowerCase())).sort(function (a, b) {
  return a.name - b.name;
}).map((i)=>{
          return <Row 
          FirstName={i.firstName} 
          LastName={i.lastName} 
          Email={i.email} 
          Role={i.role} 
          DateOfLastReview={i.dateOfLastReview} 
          Reviews={i.reviews}  
          key={i.id} 
          DeleteButton={
            <ToolTipButton        
            Title={`Delete Employee`}
            Icon={<CancelIcon htmlColor='tomato'/>}
            onClick={()=>removeEmployee(i.id)}
            />
        }
          EditButton={
            <ToolTipButton        
            Title={`Edit Employee`}
            Icon={<EditIcon htmlColor='dodgerBlue'/>}
            onClick={()=>LoadEditLearner(i.id)}
            />
        }
           />
         })}
        </TableBody>
      </Table>
    </TableContainer>
    </TableContainer>
    <React.Fragment>
    <Modal size="lg" style={{zIndex:100000}} show={open} onHide={handleClose}>
     
     <Modal.Body>
     
        <div>
        <Button style={{fontSize:'15px', float:'left', display:displayBack}} onClick={()=>{setAddMultipleEmployeeDisplay(false); setAddSingleEmployeeDisplay(false); setDisplayBack('none'); setUploadSelectDisplay(true)}}>BACK</Button>
          <Button style={{fontSize:'15px', float:'right'}} onClick={handleClose}>X</Button>
          </div>
          <div style={{marginTop:'20px', padding:'20px'}}>
       <Box sx={{display:uploadSelectDisplay ? 'block': 'none'}}>
       <SelectUploadButtonGroup SingleOnClick={()=>{setAddSingleEmployeeDisplay(true); setUploadSelectDisplay(false); setDisplayBack('block')}} MultiOnClick={()=>{setAddMultipleEmployeeDisplay(true); setUploadSelectDisplay(false); setDisplayBack('block')}}/>
       </Box>

       <Box sx={{display:addSingleEmployeeDisplay ? 'block': 'none'}}>
       <AddEmployeeForm 
       
    
    PersonsFirstName={handleFirstNameChange}  
    PersonsLastName={handleLastNameChange}  
   
    PersonsEmail={(event)=>{handleEmailInput(event)}} 
    OnChangeRadio={(e)=>{ employeeType = e.target.value; console.log(employeeType)}}
    OnSubmit={()=>{
      
      addPersonToData(firstNameInput,lastNameInput,emailInput,employeeType)
     
    
      handleClose()
      
    }} />       </Box>


       <Box sx={{display:addMultipleEmployeeDisplay ? 'block': 'none' }}>
       <AddMultipleEmployees
    OnFileChange={changeHandler}
    AddEmployees={addEmployees}
    Id={inputFile}
    ClearEmployees={()=>{
     
      setResult([]); 
      setDisplayAddBtn(false); 
    inputFile.current.value ='';
    }}
    TableBody={result}
    AddDisplayBtn={displayAddBtn ? 'block': 'none'}
    />       </Box>
       
           </div>
           </Modal.Body>
      </Modal>


      <Modal size="lg" style={{zIndex:100000}} show={showEdit} onHide={handleCloseEdit}>
     
     
        <Modal.Body>
         <EditEmployeeForm
         ChangePersonsFirstName={(e)=>setEditFirstName(e.target.value)}
         PersonsFirstNameValue={editFirstName}
         ChangePersonsLastName={(e)=>setEditLastName(e.target.value)}
         PersonsLastNameValue={editLastName}
         ChangePersonsEmail={(e)=>setEditEmail(e.target.value)}
         PersonsEmailValue={editEmail}
         CatSelectValue={editCategory}
         ChangeCatSelect={(e)=>setEditCategory(e.target.value)}
         CloseEdit={()=>handleCloseEdit()}
         OnSubmit={()=>EditEmployee()}
         
         />

   
        </Modal.Body>
        
      </Modal>
    </React.Fragment>
             </div>

             
    )
          }
}


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxHeight:'90%',
  overflow:'scroll',
  width:'60%',
  backgroundColor: 'white',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};
export default Employees;
