import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Form } from "react-bootstrap";
import { Modal } from 'react-bootstrap';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import CancelIcon from '@mui/icons-material/Cancel';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ArchiveIcon from '@mui/icons-material/Archive';
import { Chart } from "react-google-charts";
import { Document } from '../components/Document';
import axios from 'axios';
import { API } from '../Controllers/API';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import '../assets/css/Dashboard.css'
import { UpcomingEvaluations } from '../components/UpcomingEvaluations.jsx';
import ToolTipButton from '../components/TooltipButton';
import { CircularProgress } from '@mui/material';

function createData(name,type, lastReview, progress, status, id, history) {
  return {
    name,
    type,
    lastReview,
    progress,
    status,
    id,
    history,
  };
}

function Row(props) {
  const data = JSON.parse(sessionStorage.getItem('profile-data'))
  const { row } = props;
  const [showDocument, setShowDocument] = useState(false);
  const handleCloseDocument = () => setShowDocument(false);
  const handleShowDocument = (a,b,c,d ) => {
    console.log(a + " " + b + " " + c)
    setShowDocument(true);
    setDocument(
    <Document
    UserID={data.id}
    EmployeeID={a}
    ReviewID={b}
    ReviewerID={c}
    ReviewerName={d}
    />)
  }
  const copyLink = async (a,b,c)=>{
    console.log(a + " " + b + " " + c)
    let link = 'http://pec.helpdeskforhr.com/#/review?a='+data.id+'&b=' + a+'&c='+b+'&d='+c
    await navigator.clipboard.writeText(link)
    window.alert('Copied Link to clipboard. Now you can use this link to personally email the reviewer their link to complete the review.')
    }

  const resendEmail = async (a,b,c)=>{
    let link = 'http://pec.helpdeskforhr.com/#/review?a='+data.id+'&b=' + a+'&c='+b+'&d='+c
    let resp = await axios.post(API+"resend-email",{userId: data.id, employeeId:a, reviewId:b, reviewerId:c, link:link})
    
    
    console.log(resp)
    if(resp.data == 'email sent'){
      window.alert('The email has been resent to the reviewer.')
    } else{
      window.alert('Something went wrong. Please try again')
    }
    }
  
 
  const [document, setDocument] = useState(<Paper sx={{height:'1000px', padding:'15px'}}></Paper>)
  
  
  const [open, setOpen] = React.useState(false);

  
  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.name.name}
        </TableCell>
        
           <TableCell align="center">{row.reviewCreated}</TableCell>
           <TableCell align="center">{row.progress}</TableCell>
           <TableCell align="Left">{row.type}</TableCell>
           <TableCell style={{color: (row.status === 'Completed')? '#199A71': 'orange', fontWeight:'bold'}} align="center">{(row.status === 'Completed')? 'Completed √': 'In Progress'}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
            <Typography variant="button" gutterBottom component="div">
              <b>Review Type:</b> <span>{row.type}</span> 
              <div style={{display:'inline', float:'right'}}>
            
              <ToolTipButton 
              onClick={props.ClickDelete} 
              Title="Delete Evaluation"
              Icon={<CancelIcon htmlColor='tomato'/>}
              />
              </div>
             
              </Typography> 
              <Typography variant="button" gutterBottom component="div">
               Reviewer Details
              </Typography>
              
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell align="center">Status</TableCell>
                    <TableCell align="center">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.history.map((historyRow) => (
                    <TableRow key={historyRow.name}>
                      <TableCell component="th" scope="row">
                        {historyRow.name}
                      </TableCell>
                      <TableCell>{historyRow.email}</TableCell>
                      <TableCell style={{color: (historyRow.status === 'completed')? '#199A71': 'orange', fontWeight:'bold'}} align="center">{(historyRow.status === 'completed')? 'Completed √': 'Pending'}</TableCell>
                      <TableCell align="center">
                      <ToolTipButton
                        style={{display: (historyRow.status === 'completed') ? 'none':'inline'}}
                        Title={`Resend Email To ${historyRow.name}`}
                        Icon={<ForwardToInboxIcon htmlColor='#199A71'/>}
                        onClick={()=>resendEmail(row.name.id, row.id, historyRow.id)}
                        />
                        <ToolTipButton
                        style={{display: (historyRow.status === 'completed') ? 'none':'inline'}}
                        Title={`Copy Link To Review`}
                        Icon={<ContentCopyIcon htmlColor='#199A71'/>}
                        onClick={()=>copyLink(row.name.id, row.id, historyRow.id)}
                        />
                        <ToolTipButton
                        style={{display: (historyRow.status === 'completed') ? 'inline':'none'}} 
                        Title={`View Document`}
                        Icon={<PlagiarismIcon htmlColor='#199A71'/>}
                        onClick={()=>handleShowDocument(row.name.id, row.id, historyRow.id, historyRow.name)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>

      
      <Modal style={{zIndex:'10000'}} size="lg" show={showDocument} onHide={handleCloseDocument}>
        <Modal.Header closeButton>
          
        </Modal.Header>
        <Modal.Body>
          {document}
        </Modal.Body>
       
      </Modal>
    </React.Fragment>
  );
}


Row.propTypes = {
  row: PropTypes.shape({
    reviewCreated: PropTypes.string.isRequired,
    progress: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      }),
    ).isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};


export default function RecentReviews(props) {
  const data = JSON.parse(sessionStorage.getItem('profile-data'))

  const [loading, setLoading] = useState(true)
  const [rowed, setRowed]= useState([])
  const [reviewChartData,setReviewedChartData] = useState()
  const [reviewTypeChartData,setReviewTypeChartData] = useState()
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [filter, setFilter] = useState('')
  const [roleFilter, setRoleFilter] = useState('')
  const [showDocument, setShowDocument] = useState(false);
  const [update,setUpdate] = useState(false)
  const RemoveReview = (a,b)=>{
    console.log(a)
    let message = window.confirm("This will delete this review and any progress. Are you sure you would like to do this?")
  
  
    if(message){

      axios.post(API+'remove-review', {email:data.email,employeeId:a, reviewId:b}).then((response)=>{
  return response
    }).then((response)=>{
     console.log(response)
     setupData()
     setUpdate(!update)
    })
  }
}

  const setupData = (a)=>{

    let arr = []
    
    axios.post(API,{email:data.email}).then((response)=>{
      return response
        }).then((response)=>{
          console.log(response)
          if(response.data[0].employees.length > 0){
            
            response.data[0].employees.forEach(i=>{
              if(i.reviews.length > 0){
    
           
             console.log(i.reviews)
    
             i.reviews.map((a)=>{
    
              let progressFilter = a.reviewers.filter(i=> {return (i.status === "completed")})
              let statusUpdate;
      
              if(progressFilter.length === a.reviewers.length){
                statusUpdate = "Completed"
              } else{
                statusUpdate = 'In Progress'
              }
              console.log(a)
              let date = a.dateCreated
              arr.push({
                name:a.reviewee,
                type: a.reviewType,
                reviewCreated: date,
                progress:`${progressFilter.length}/${a.reviewers.length}`,
                status:statusUpdate,
                id:a.reviewId,
                history:a.reviewers
              })
              
             })
            }
                  
                })
                
              }
            // let reviewStatsArr = ()=>{}
              arr.forEach(i=>{

              })
              // }
              console.log(arr)

              setRowed(arr.filter((i)=>{return (i.status === 'In Progress')}))
          //   })
          return response
          
          // }
        }).then((response)=>{
        
          let employeesReviewed = [];
          let employeesNotReviewed = [];
          response.data[0].employees.forEach(i=>{
            
          console.log(i.reviews.length)
          console.log(i.name)
               if(i.reviews.length > 0){
                
                   employeesReviewed.push('review')
                   console.log(employeesReviewed)
               } else{
                employeesNotReviewed.push('Notreview')
                console.log(employeesNotReviewed)
               }
             
          })
          let epeArr = []
          let threeSixtyArr = []
          let ueArr = [];
          let selfArr = [];
          response.data[0].employees.forEach(i=>{
            
            console.log(i.reviews.length)
            console.log(i.name)
                 if(i.reviews.length > 0){
                  i.reviews.forEach(o=>{
                    console.log(i.reviewType)
                    if(o.reviewType === "Employee Performance Evaluation"){
                         epeArr.push('this')
                    }

                    if(o.reviewType === "Employee Self Evaluation"){
                      selfArr.push('this')
                 }

                 if(o.reviewType === "360 Evaluation"){
                  threeSixtyArr.push('this')
             }

             if(o.reviewType === "Upward Evaluation"){
              ueArr.push('this')
         }
                  })
             
                 } 
               
            })
          let reviewTypeStats = [["Review Types","Evaluations Conducted", ],["Employee Performance Evaluation", epeArr.length],["Employee Self Evaluation",selfArr.length],["360 Evaluation", threeSixtyArr.length],["Upward Evaluation",ueArr.length]]
          let reviewStatsArr = [['Employees','Reviewed'],["Employee's Reviewed", employeesReviewed.length],["Employee's Not Reviewed", employeesNotReviewed.length]];
          console.log(reviewStatsArr)
          setReviewedChartData(reviewStatsArr)
          setReviewTypeChartData(reviewTypeStats)
        })
       
    }

    
     const options = {
      title: "Evaluation Ratio's",
     legend:{position: 'bottom'},
     titleTextStyle:{
      fontSize:14,
      bold:true
     },
      pieHole: 0.4,
      is3D: false,
    };

    const typeOptions = {
      chart: {
        title: "Evaluation Conducted By Type",
       
        
      },
      titleTextStyle:{
        fontSize:14,
        bold:true
       },      
      bars: 'horizontal',
      bar: { groupWidth: "45%" },
      legend: {position: 'none'},
      
    };
      useEffect(()=>{
       setupData()
        setLoading(false)
       },[])
       if(loading){
        return(<Box sx={{textAlign:'center', marginTop:20}}>
        <CircularProgress/>
        <Typography><b>Loading</b></Typography>
      </Box>)
       } else{
        
  return (
    <div>
    <Box style={{textAlign:'center'}} >
    <Paper
    className='box' 
      sx={{
        margin:'5px',
       display:'inline-block',
        flexDirection: 'column',
        height: '30%',

      }}
    >
   <Chart
      chartType="PieChart"
      width="100%"
      height="340px"
      data={reviewChartData}
      options={options}
    />
    
    </Paper>

    <Paper
    className='box' 
      sx={{
        margin:'5px',
        padding:'20px',
        display:'inline-block',
        flexDirection: 'column',
        height: '30%'
      }}
    >
  <Chart
      chartType="Bar"
      width="100%"
      height="300px"
      data={reviewTypeChartData}
      options={typeOptions}
    />
    
    </Paper>


    <Paper
    sx={{
      margin:'5px',
      padding:'20px',

    
    }}
    >
    <Typography variant='button'><b>ONGOING EVALUATIONS</b></Typography>
    <div style={{textAlign:'end', paddingBottom:'20px'}}>
</div>

<div style={{textAlign:'start', paddingBottom:'20px'}}>

<Box sx={{marginBottom:10, display:'inline'}}>
  <Form.Control onChange={(e)=>{setFilter(e.target.value)}} placeholder="Search..." style={{width:'33%', display:'inline'}}/>
  <Form.Select onChange={(e)=>setRoleFilter(e.target.value)} style={{width:'35%', float:'right', display:'inline'}}>
    <option value="">Show Review Type</option>
    <option value="">All</option>
    <option value="Employee Performance Evaluation">Employee Performance Evaluation</option>
    <option value="Employee Self Evaluation">Employee Self Evaluation</option>
    <option value="360 Evaluation">360 Evaluation</option>
    <option value="Upward Evaluation">Upward Evaluation</option>
  </Form.Select>
</Box>
</div>
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell align="left"><b>Employee Name</b></TableCell>
            <TableCell align="center"><b>Review Created On:</b></TableCell>
            <TableCell align="center"><b>Progress</b></TableCell>
            <TableCell align="center"><b>Review Type</b></TableCell>
            <TableCell align="center"><b>Overall Status</b></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rowed.filter(f=>f.type.toLowerCase().includes(roleFilter.toLowerCase())).filter(o=> o.name.name.toLowerCase().includes(filter.toLowerCase())).map((row) => {
            console.log(row)
              return (  <Row ClickDelete={()=>RemoveReview(row.name.id, row.id)} key={row.id} row={row} />
              )
          }
          )}
        </TableBody>
      </Table>
    </TableContainer>
    
    </Paper>

    <Paper
    sx={{
      margin:'5px',
      padding:'20px',

    
    }}
    >
    <Typography variant='button'><b>UPCOMING EVALUATIONS</b></Typography>
    <Typography variant="subtitle2">This section will display the evaluations you have scheduled for the current month.</Typography>
    <UpcomingEvaluations SetupReview={props.SetupReview}/></Paper>
    </Box>
    </div>
  );
}

}