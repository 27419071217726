import React, {useRef, useState} from "react";
import { Form } from "react-bootstrap";
import { InputGroup } from "react-bootstrap";
import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
import {saveAs} from "file-saver";
import doc from '../../documents/esr-sample.docx'
import {AppBar, Button, Toolbar} from "@mui/material";
import { Divider, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import '../../assets/css/review-screen.css'
import logo from '../../assets/css/images/PECLogo.png'
import hdLogo from '../../assets/css/hd-logo-clr.png'
import { Link } from "react-router-dom";


function Copyright(props) {
    return (
      <div style={{textAlign:'center'}}>
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href="https://helpdeskforhr.com">
          Andere HR Inc
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
        
      </Typography>
      <Typography sx={{fontWeight:'bold'}} variant="caption">POWERED BY</Typography>
      <br></br>
      <img src={hdLogo} height='auto' width='100px'/>
      </div>
    );
  }
export const SelfWebDocument = (props)=>{
    const refContainer = useRef();
const[body, setBody] = useState("")
// async function downloadDocx(params) {
//     const converted = htmlDocx.asBlob(params);
//     saveAs(converted, 'test.docx');
//   }
function loadFile(url, callback) {
    PizZipUtils.getBinaryContent(url, callback);
}
function format(inputDate) {
    var date = new Date(inputDate);
    if (!isNaN(date.getTime())) {
        // Months use 0 index.
        return date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear();
    }
}

let  obj = {
    emp_Name:props.RevieweeName,
    employee_id_no :(props.EmployeeId === '')? 'NA': props.EmployeeId,
    job_title: (props.JobTitle === '')? 'NA': props.JobTitle,
    department: (props.Department === '')? 'NA': props.Department,
    rev_sup:props.Reviewer,
    rev_period: (props.ReviewPeriod === '')? 'NA': props.ReviewPeriod,
    t_o_p: (props.TimeInPosition === '')? 'NA': props.TimeInPosition,
    part_two_a: (props.PartTwoTextArea === '')? 'NA': props.PartTwoTextArea,
    overall_rating: props.OverallScore,
    part_4_a:props.PartFourATextArea,
    part_4_b:props.PartFourATextArea,
    categories: props.WorkSheetDocData,
    signature: props.Signature,
    date_sign: format(props.SignatureDate)
    
   
}



  const generateDocument = () => {
    loadFile(
        doc,
        function (error, content) {
            if (error) {
                throw error;
            }
            const zip = new PizZip(content);
            const doc = new Docxtemplater(zip, {
                paragraphLoop: true,
                linebreaks: true,
            });

            // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
            doc.render(obj);
            const blob = doc.getZip().generate({
                type: "blob",
                mimeType:
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            }); //Output the document using Data-URI
            saveAs(blob, `${props.RevieweeName} Self Review.docx`);
        }
    );
};



    return (
        <div style={{backgroundColor:'#D5EDE3'}}>
        <Box className='review-paper-container' sx={{backgroundColor:'#D5EDE3', paddingTop:10,paddingLeft:10,paddingRight:10,display:'flex', justifyContent:'center', minHeight:'100vh'}}>
       <AppBar>
       <Toolbar
            sx={{
              backgroundColor:'#1A9A71',
              pr: '24px', // keep right padding when drawer closed
            }}
          >

<img className="logo" height='auto' width={250} src={logo}/>
<Button sx={{color:'white', position:'absolute', top:15, right:15}} onClick={()=>{
        
        generateDocument()
       
    }}>Download Document</Button>
          </Toolbar>
     
       </AppBar>
        
      
      
        <Paper className="web-doc-paper" ref={refContainer} sx={{textAlign:'center', padding:'50px', maxWidth:'950px',justifyContent:'center'}}>
            <Box sx={{textAlign:'start',display:'flex', flexDirection:'row', flexWrap: 'wrap'}}>
            <Form.Group style={{flex:'50%'}}>
                <Form.Label><b>PERSON BEING REVIEWED:</b></Form.Label>
                <br></br>
                <Form.Label>{props.RevieweeName}</Form.Label>
            </Form.Group>

            <Form.Group style={{flex:'50%'}}>
                <Form.Label><b>REVIEWER:</b></Form.Label>
                <br></br>
                <Form.Label>{props.Reviewer}</Form.Label>
            </Form.Group>

            <Form.Group style={{flex:'50%', display: (props.JobTitle === '') ? 'none': 'block'} }>
                <Form.Label><b>JOB TITLE:</b></Form.Label>
                <br></br>
            <Form.Label>{props.JobTitle}</Form.Label>
            </Form.Group>


            <Form.Group style={{flex:'50%', marginTop:'10px', display: (props.ReviewPeriod === '') ? 'none': 'block'}}>
                <Form.Label><b>REVEIW PERIOD:</b></Form.Label>
                <br></br>
                <Form.Label>{props.ReviewPeriod}</Form.Label>
            </Form.Group>

            <Form.Group style={{flex:'50%', marginTop:'10px', display: (props.EmployeeId === '') ? 'none': 'block'}}>
                <Form.Label><b>EMPLOYEE ID:</b></Form.Label>
                <br></br>
                <Form.Label>{props.EmployeeId}</Form.Label>
            </Form.Group>

            <Form.Group style={{flex:'50%',marginTop:'10px', display: (props.Department === '') ? 'none': 'block'}}>
                <Form.Label><b>DEPARTMENT:</b></Form.Label>
                <br></br>
                <Form.Label>{props.Department}</Form.Label>
            </Form.Group>


            <Form.Group style={{flex:'50%', marginTop:'10px', display: (props.TimeInPosition === '') ? 'none': 'block'}}>
                <Form.Label><b>TIME IN POSITION:</b></Form.Label>
                <br></br>
                <Form.Label>{props.TimeInPosition}</Form.Label>
            </Form.Group>
            
            </Box>
            <hr></hr>
            <Box style={{textAlign:'start'}}>
            <Typography variant="button" style={{fontSize:16, textAlign:'start'}}><b>PART ONE: REVIEW OF PERFORMANCE ELEMENTS</b></Typography>
            </Box>
            <hr></hr>

            <Box style={{textAlign:'start'}}>
                {props.WorksheetData}

<hr></hr>
            <Box style={{textAlign:'start'}}>
            <Typography variant="button" style={{fontSize:16, textAlign:'start'}}><b>PART TWO: REVIEW OF PERFORMANCE ELEMENTS</b></Typography>
            </Box>
            <hr></hr>

            <Box>
                <Typography variant="p">Supervisor describes employee’s major assignments and accomplishments, key strengths, any performance shortfalls, and other performance elements that characterize the employee’s performance during the review period. Please include examples or supporting information below.</Typography>
              
                 <div style={{padding:'10px', borderRadius:'10px', minHeight:'150px',border:'1px solid black'}}>
                {props.PartTwoTextArea}
                </div>
            </Box>


            <hr></hr>
            <Box style={{textAlign:'start'}}>
            <Typography variant="button" style={{fontSize:16, textAlign:'start'}}><b>PART THREE: OVERALL PERFORMANCE RATING</b></Typography>
            </Box>
            <hr></hr>

            <Box>
               <Typography variant="button"><b>{props.OverallScore}</b></Typography>
            </Box>
            <Box style={{ display:props.GoalDisplay ? 'block' : 'none' }}>
            <hr></hr>
            <Box style={{textAlign:'start'}}>
            <Typography variant="button" style={{fontSize:16, textAlign:'start'}}><b>PART FOUR A: PERFORMANCE GOALS FOR NEXT REVIEW PERIOD</b></Typography>
            </Box>
            <hr></hr>
            <Box>
                <Typography variant="p">Supervisor identifies goals for the upcoming review period to ensure continued contributions from the employee for success within the organization.</Typography>
               
                <div style={{padding:'10px', borderRadius:'10px', minHeight:'150px',border:'1px solid black'}}>
                {props.PartFourATextArea}
                </div>
            <hr></hr>
            <Box style={{textAlign:'start'}}>
            <Typography variant="button" style={{fontSize:16, textAlign:'start'}}><b>PART FOUR B: GOALS FOR/AND PROGRESS ON PROFESSIONAL DEVELOPMENT</b></Typography>
            </Box>
            <hr></hr>

                <Typography variant="p">Supervisor lists goals related to the employee’s professional (or managerial) development. Consider the employee’s performance improvement needs and action plan, training recommendations, future goals and expectations.</Typography>
                <div style={{padding:'10px', borderRadius:'10px', minHeight:'150px',border:'1px solid black'}}>
                {props.PartFourBTextArea}
                </div>
            
            </Box>
            </Box>
            <Box sx={{marginTop:'20px'}}>
            <Form.Group style={{flex:'50%',marginTop:'10px'}}>
            <hr></hr>
            <Box style={{textAlign:'start'}}>
            <Typography variant="button" style={{fontSize:16, textAlign:'start'}}><b>SIGNATURE</b></Typography>
            </Box>
            <hr></hr>
              
            <InputGroup className="mb-3">
            <Form.Label  style={{ fontSize:'18px', paddingTop:'4px'}} >Signature:</Form.Label>

      <Form.Label  style={{fontFamily:'Brush Script MT', fontSize:'25px', width:'50%', paddingLeft:'20px'}} >{props.Signature}</Form.Label>
      <Form.Label  style={{ fontSize:'18px', paddingTop:'4px'}} >Date:</Form.Label>

<Form.Label  style={{ fontSize:'18px', paddingLeft:'20px',paddingTop:'4px'}} >{props.SignatureDate}</Form.Label></InputGroup>
  
    </Form.Group>
    </Box>
    
            </Box>
        </Paper>
    </Box>
    <Box sx={{paddingBottom:'20px'}}><Copyright sx={{ pt: 4 }} /></Box>
    </div>
    )
}