import React from "react";
import { Form } from "react-bootstrap";
import { InputGroup } from "react-bootstrap";
import htmlDocx from 'html-docx-js/dist/html-docx';
import {saveAs} from "file-saver";
import {AppBar, Button, Toolbar} from "@mui/material";
import { Divider, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import '../../assets/css/review-screen.css'
import logo from '../../assets/css/images/PECLogo.png'
import hdLogo from '../../assets/css/hd-logo-clr.png'
import { Link } from "react-router-dom";
function Copyright(props) {
    return (
      <div style={{textAlign:'center'}}>
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href="https://helpdeskforhr.com">
          Andere HR Inc
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
        
      </Typography>
      <Typography sx={{fontWeight:'bold'}} variant="caption">POWERED BY</Typography>
      <br></br>
      <img src={hdLogo} height='auto' width='100px'/>
      </div>
    );
  }
export const EPEDemo = (props)=>{


    return (
        <div style={{backgroundColor:'#D5EDE3'}}>
        <Box className='review-paper-container' sx={{backgroundColor:'#D5EDE3', paddingTop:10,paddingLeft:10,paddingRight:10,display:'flex', justifyContent:'center', minHeight:'100vh'}}>
       <AppBar style={{marginBottom:'70px !important'}}>
       <Toolbar
            sx={{
              backgroundColor:'#1A9A71',
              pr: '24px', // keep right padding when drawer closed
            }}
          >

<img height='auto' width={250} src={logo}/>
<Button sx={{color:'white', position:'absolute', top:15, right:15}} onClick={()=>{
        window.close()
       }}>Close</Button>
          </Toolbar>

<Typography sx={{padding:1}} variant="p"><b>DEMO: This is a DEMO page for you to see and not the actual evaluation. However, this is exactly how the evaluation will look to the reviewer.</b></Typography>


       </AppBar>

       <br></br>
        <Paper className="demo-paper" sx={{textAlign:'center', padding:'50px', maxWidth:'950px',marginTop:'70px',justifyContent:'center'}}>
           
        <Box sx={{textAlign:'start',display:'flex', flexDirection:'row', flexWrap: 'wrap'}}>
            <Form.Group style={{flex:'50%'}}>
                <Form.Label><b>PERSON BEING REVIEWED:</b></Form.Label>
                <br></br>
                <Form.Label>{props.RevieweeName}</Form.Label>
            </Form.Group>

            <Form.Group style={{flex:'50%', display: (props.JobTitle === '') ? 'none': 'block'} }>
                <Form.Label><b>JOB TITLE:</b></Form.Label>
                <br></br>
            <Form.Label>{props.JobTitle}</Form.Label>
            </Form.Group>


            <Form.Group style={{flex:'50%', marginTop:'10px', display: (props.ReviewPeriod === '') ? 'none': 'block'}}>
                <Form.Label><b>REVIEW PERIOD:</b></Form.Label>
                <br></br>
                <Form.Label>{props.ReviewPeriod}</Form.Label>
            </Form.Group>

            <Form.Group style={{flex:'50%', marginTop:'10px', display: (props.EmployeeId === '') ? 'none': 'block'}}>
                <Form.Label><b>EMPLOYEE ID:</b></Form.Label>
                <br></br>
                <Form.Label>{props.EmployeeId}</Form.Label>
            </Form.Group>

            <Form.Group style={{flex:'50%',marginTop:'10px', display: (props.Department === '') ? 'none': 'block'}}>
                <Form.Label><b>DEPARTMENT:</b></Form.Label>
                <br></br>
                <Form.Label>{props.Department}</Form.Label>
            </Form.Group>


            <Form.Group style={{flex:'50%', marginTop:'10px', display: (props.TimeInPosition === '') ? 'none': 'block'}}>
                <Form.Label><b>TIME IN POSITION:</b></Form.Label>
                <br></br>
                <Form.Label>{props.TimeInPosition}</Form.Label>
            </Form.Group>
            
            </Box>
            <hr></hr>
            <Box style={{textAlign:'start'}}>
            <Typography variant="button" style={{fontSize:16, textAlign:'start'}}><b>PART ONE: REVIEW OF PERFORMANCE ELEMENTS</b></Typography>
            </Box>
            <hr></hr>

            <Box style={{textAlign:'start'}}>
                {props.WorksheetData}

<hr></hr>
            <Box style={{textAlign:'start'}}>
            <Typography variant="button" style={{fontSize:16, textAlign:'start'}}><b>PART TWO: REVIEW OF PERFORMANCE ELEMENTS</b></Typography>
            </Box>
            <hr></hr>

            <Box>
                <Typography variant="p">Describe employee’s major assignments and accomplishments, key strengths, any performance shortfalls, and other performance elements that characterize the employee’s performance during the review period. Please include examples or supporting information below.</Typography>
                <Form.Control
                style={{marginTop:'10px'}}
                as='textarea'
                placeholder="No text can be added here as this is a demo page only to demonstrate how the review will look."
                disabled
                />
            </Box>


            <hr></hr>
            <Box style={{textAlign:'start'}}>
            <Typography variant="button" style={{fontSize:16, textAlign:'start'}}><b>PART THREE: OVERALL PERFORMANCE RATING</b></Typography>
            </Box>
            <hr></hr>

            <Box>
                <Form.Select>
                        <option>---</option>
                        <option>Exceptional Performance (outstanding achievements that far exceed goals in all performance elements)</option>
                        <option>Exceeds Expectations (performance that consistently goes beyond meeting goals)</option>
                        <option>Fully Meets Expectations (commendable performance that meets goals)</option>
                        <option>Partially Meets Expectations (inconsistent aspects of performance requiring improvement to meet some goals)</option>
                        <option>Does Not Meet Requirements (goals unmet, skills not demonstrated, improvement needed)</option>
                        
                </Form.Select>
            </Box>
            <Box style={{ display:props.GoalDisplay ? 'block' : 'none' }}>
            <hr></hr>
            <Box style={{textAlign:'start'}}>
            <Typography variant="button" style={{fontSize:16, textAlign:'start'}}><b>PART FOUR A: PERFORMANCE GOALS FOR NEXT REVIEW PERIOD</b></Typography>
            </Box>
            <hr></hr>
            <Box>
                <Typography variant="p">Identify goals for the upcoming review period to ensure continued contributions from the employee for success within the organization.</Typography>
                <Form.Control
                style={{marginTop:'10px'}}
                as='textarea'
                placeholder="No text can be added here as this is a demo page only to demonstrate how the review will look."
                disabled
                />

            <hr></hr>
            <Box style={{textAlign:'start'}}>
            <Typography variant="button" style={{fontSize:16, textAlign:'start'}}><b>PART FOUR B: GOALS FOR/AND PROGRESS ON PROFESSIONAL DEVELOPMENT</b></Typography>
            </Box>
            <hr></hr>

                <Typography variant="p">List goals related to the employee’s professional (or managerial) development. Consider the employee’s performance improvement needs and action plan, training recommendations, future goals and expectations.</Typography>
                <Form.Control
                style={{marginTop:'10px'}}
                as='textarea'
                placeholder="No text can be added here as this is a demo page only to demonstrate how the review will look."
                disabled
                />
            </Box>
            </Box>
            <Box sx={{marginTop:'20px'}}>
            <Form.Group style={{flex:'50%',marginTop:'10px'}}>
            <hr></hr>
            <Box style={{textAlign:'start'}}>
            <Typography variant="button" style={{fontSize:16, textAlign:'start'}}><b>SIGNATURE</b></Typography>
            </Box>
            <hr></hr>
              
            <InputGroup className="mb-3">
            <InputGroup.Text >Signature</InputGroup.Text>
      <Form.Control style={{fontFamily:'Signature', fontSize:'18px'}} aria-label="Signature" />
      <InputGroup.Text>Date</InputGroup.Text>
      <Form.Control type="date" style={{maxWidth:'200px'}} aria-label="Date" />
    </InputGroup>
    </Form.Group>
    </Box>
    
            </Box>
        </Paper>
    </Box>
    <Box sx={{paddingBottom:'20px'}}><Copyright sx={{ pt: 4 }} /></Box>
       </div>
    )
}