import React from "react";
import { atcb_init } from "add-to-calendar-button";
import '../assets/css/calendar.css';

export const AddToCalendar = (props) => {
  React.useEffect( () => { atcb_init() }, []);
  return (
    <div className="atcb">
      { '{' }
        "name":"Setup Performance Evauation for {props.Name}",
        "description":"Go to pec.HelpDeskforHR.com and setup a performance evaluation for {props.Name}",
        "startDate":"{props.Date}",
        "endDate":"{props.Date}",
        "location":"NA",
        "checkmark":"false",
        "label":"Add to Calendar",
        "options":[
          "Apple",
          "Google",
          "iCal",
          "Microsoft365",
          "Outlook.com",
          "Yahoo"
        ],
        "timeZone":"currentBrowser",
        "iCalFileName":"Reminder-Event Performance Evaluation"
      { '}' }
    </div>
  );
}